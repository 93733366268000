import { useFormik } from "formik";
import { useHistory } from "react-router-dom";

const Login = () => {
    const history = useHistory()
    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        onSubmit: values => {
            if ((values.email === 'info@urban-pieces.com' && values.password === 'david123953')) {
                localStorage.setItem('user', true)
                history.push("/home");
            }
        },
    });

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            width: '70%',
            margin: 'auto'
        }}>
            <form onSubmit={formik.handleSubmit}>
                <input style={{
                    width: '100%',
                    padding: '1em .5em',
                    margin: '.5em',
                    background: 'rgb(250,250,250)',
                    border: 0,
                    borderRadius: '10px',
                }} placeholder="Login"
                    id="email"
                    name="email"
                    type="email"
                    onChange={formik.handleChange}
                    value={formik.values.email} />
                <input style={{
                    width: '100%',
                    padding: '1em .5em',
                    margin: '.5em',
                    background: 'rgb(250,250,250)',
                    border: 0,
                    borderRadius: '10px',
                }} placeholder="Password"
                    id="password"
                    name="password"
                    type="password"
                    onChange={formik.handleChange}
                    value={formik.values.password} />
                <button style={{
                    width: '100%',
                    backgroundColor: '#69a7c2',
                    color: 'white',
                    fontSize: '1em',
                    padding: '1em 0',
                    border: 0,
                    borderRadius: '10px',
                    margin: '.5em',
                    cursor: 'pointer'
                }}
                    type="submit"
                >Enter</button>
            </form>
        </div>
    )
}

export default Login