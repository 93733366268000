import './App.css';
import ROUTES, { RenderRoutes } from './routes';

function App() {
  return (
    <div style={{ display: "flex", height: "100vh", alignItems: "stretch" }}>
      <RenderRoutes routes={ROUTES}/>
    </div>
  );
}

export default App;
