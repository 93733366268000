import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { RetreatOrderIntegration } from "../integration/retreat_order";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import XLSX from 'xlsx';

const Home = () => {
    const [total, setTotal] = useState(0)
    const [progress, setProgress] = useState([])
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [percent, setPercent] = useState(0)

    useEffect(() => {
        if (percent < 100 && progress && progress.length) {
            setPercent(Math.floor((progress.length * 100) / total))
        }
    }, [progress])

    useEffect(() => {if (percent >= 100) {
            alert('Download starting now')
            let binaryWS = XLSX.utils.json_to_sheet(progress);
            var wb = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(wb, binaryWS, 'Binary values') 
            // export your excel
            XLSX.writeFile(wb, `Report (${new Date(startDate).toLocaleDateString().split('/').join('-')} - ${new Date(endDate).toLocaleDateString().split('/').join('-')}).xlsx`);
        }
    }, [percent])

    const formik = useFormik({
        initialValues: {
            startDate: '',
            endDate: ''
        },
        onSubmit: values => {
            alert("The download will start in some moments, please don't close the window")
            RetreatOrderIntegration.retreatIds(startDate, endDate, (response) => {
                setTotal(response.data.length)
                for (const item of response.data) {
                    RetreatOrderIntegration.getItems(item, (res) => {
                        setProgress(oldProgress => [...oldProgress, res.data.row]);
                    })
                }
            })
        },
    });


    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '1em',
            width: '100%',
        }}>
            <form onSubmit={formik.handleSubmit} style={{
                width: '60%'
            }}>
                <div style={{
                    display: 'flex',
                    alignContent: 'center',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '1em',
                    width: '80%',
                    margin: 'auto'
                }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignContent: 'center',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}>
                        <span style={{
                            margin: '0 .5em'
                        }}>Start Date</span>
                        <DatePicker selected={startDate} onChange={date => setStartDate(date)} />
                    </div>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignContent: 'center',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}>
                        <span style={{
                            margin: '0 .5em'
                        }}>End Date</span>
                        <DatePicker selected={endDate} onChange={date => setEndDate(date)} />
                    </div>
                </div>

                <div style={{
                    display: 'flex',
                    alignContent: 'center',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '1em',
                    width: '100%',
                    margin: 'auto'
                }}>
                    <button style={{
                        width: '80%',
                        backgroundColor: '#69a7c2',
                        color: 'white',
                        fontSize: '1em',
                        padding: '1em 0',
                        border: 0,
                        borderRadius: '10px',
                        margin: '.5em',
                        cursor: 'pointer'
                    }}
                        type="submit"
                    >Download</button>
                    <span>{percent} %</span>
                </div>

            </form>
        </div>
    )
}

export default Home