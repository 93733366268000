import axios from "axios";

const baseURL = "https://secure-peak-38604.herokuapp.com/api/shopware";

const retreatIds = (start_date, end_date, callback = () => {}) => {
    axios.post(`${baseURL}/getIds`, {
        start_date,
        end_date
    })
        .then(function (response) {
            callback(response)
        })
        .catch(function (error) {
            console.log(error);
        });
}

const getItems = (order, callback = () => {}) => {
    axios.post(`${baseURL}/getOrders`, {
        order
    })
        .then(function (response) {
            callback(response)
        })
        .catch(function (error) {
            console.log(error);
        });
}

export const RetreatOrderIntegration = {
    retreatIds,
    getItems
}